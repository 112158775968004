import Resizer from 'react-image-file-resizer';
import { MAX_FILE_SIZE } from '../../../consts/appConfig';

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      720,
      1024,
      'JPEG',
      40,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });

export const checkAndCompressFile = async (file) => {
  if (file) {
    if (file.type.includes('video')) {
      if (file.size > MAX_FILE_SIZE) {
        return null;
      }
      return file;
    }
    try {
      const image = await resizeFile(file);
      return image;
    } catch (e) {
      return file;
    }
  }
};
