import { Style, Circle, Fill, Stroke, Text } from 'ol/style';
import axios from 'axios';
import UserSessionDataHandler from '../../../auth/UserSessionDataHandler';
import theme from '../../../consts/theme';

export const setBasicLocalizationStyle = (
  localizationFeature,
  numberOfPlaces = 0
) => {
  const radius = 10 + (numberOfPlaces > 10 ? 10 : numberOfPlaces);
  const textColor =
    theme[UserSessionDataHandler.getSettings()?.theme].palette.secondary.main ||
    'rgba(0,125,125,1)';
  const style = new Style({
    image: new Circle({
      radius,
      fill: new Fill({ color: [80, 80, 80, 1] }),
      stroke: new Stroke({
        color: [125, 125, 125, 0.4],
        width: 8,
      }),
    }),
    text: new Text({
      text:
        !!numberOfPlaces && numberOfPlaces !== 1
          ? numberOfPlaces?.toString()
          : '',
      scale: 1.2,
      fill: new Fill({ color: textColor }),
      font: 'bold 12px sans-serif',
      textAlign: 'center',
      textBaseline: 'bottom',
      offsetY: 10,
      // offsetX: 9,
    }),
  });

  if (localizationFeature) localizationFeature.setStyle(style);
};

export const setSelectedLocalizationStyle = (localizationFeature) => {
  const style = new Style({
    image: new Circle({
      radius: 10,
      fill: new Fill({ color: [0, 255, 0, 0.4] }),
      stroke: new Stroke({
        color: [0, 255, 0],
        width: 2,
      }),
    }),
  });

  if (localizationFeature) localizationFeature.setStyle(style);
};

export const setGroupedLocalizationStyle = (localizationFeature) => {
  const style = new Style({
    image: new Circle({
      radius: 10,
      fill: new Fill({ color: [168, 168, 168, 0.4] }),
      stroke: new Stroke({
        color: [168, 168, 168],
        width: 2,
      }),
    }),
  });

  if (localizationFeature) localizationFeature.setStyle(style);
};

export const getLocalizationNameByCoordinates = async (coordinates) => {
  const result = await axios.get(
    `https://photon.komoot.io/reverse?lon=${coordinates[0]}&lat=${coordinates[1]}`
  );

  return (
    result.data.features[0]?.properties?.city ||
    result.data.features[0]?.properties?.name ||
    null
  );
};
