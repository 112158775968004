import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  main: {
    position: 'relative',
    width: '150px',
    minWidth: '50px',
    borderRadius: 20,
    padding: 10,
    fontSize: '15px',
    color: theme.palette.secondary.main,
  },
}));

const LoadMoreButton = (props) => {
  const { top, left, onClickHandler, content } = props;
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      style={{ top, left }}
      className={classes.main}
      onClick={() => onClickHandler()}
    >
      {content}
    </Button>
  );
};

LoadMoreButton.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  onClickHandler: PropTypes.func.isRequired,
  content: PropTypes.any,
};

LoadMoreButton.defaultProps = {
  top: 10,
  left: 'calc(50% - 75px)',
  content: '+',
};

export default LoadMoreButton;
