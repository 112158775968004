import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  wrapper: {
    width: '80%',
    height: '100%',
    margin: 'auto',
    marginTop: 50,
  },
});

const PolicyRules = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <h3>Spotfinder app privacy rules (ENG)</h3>

      <h5>UGC (User Generated Content)</h5>
      <p>
        SpotFinder is a community application that allows users to share places
        they like. Use of this service is for strictly personal, non-profit,
        direct or indirect use. The addition of places belonging to private
        persons must in no case be added without their prior authorization. We
        cannot guarantee the accuracy or legality of information exchanged, all
        kinds of description including addresses, contact details, prices,
        comments and photos. SpotFinder reserves the right to moderate, delete
        or modify any information added by users, all kinds of descriptions,
        addresses, contact details, price comments and photos that our team will
        consider inconsistent with the rules. Spotfinder can not be held
        responsible for actions and consequences that may result from using the
        information obtained through our application. Users should add places in
        accordance with the main idea of the application, but we cannot
        guarantee it. It is danger that you come across content promoting
        alcohol, gambling or pornography (all this kind of content is removing
        from an app as soon as possible). When if you encounter inappropriate
        content inside application it is possible to use built in reporting
        system. We will look at this content and when we decide that it contains
        not allowed content we will delete it. As a user in user community app
        you are responsible for content you provide, and do your best to make
        this app awesome.
      </p>

      <h5>WHAT PERSONAL DATA DO WE COLLECT?</h5>
      <p>
        In order to use our app you don’t have to register an account. However,
        if you want to fully enjoy the functionalities of spotfinder you will
        create an account; In this case the minimum necessary personal data to
        be provided are: e-mail address, username (created by you, it does not
        have to be your real name). We might collect other information, not
        considered as personal data, such as: type of your phone, preferred
        language, descriptions deriving from comments and photos. Our app uses
        Google Analytics which is a service offered by Google that generates
        detailed statistics about our app's traffic. This service is provided by
        Google according to Google's privacy policy.
      </p>
      <h5>WHAT IS THE INFORMATION USED FOR?</h5>
      <p>
        Your email address is used to: register your account, log in to your
        account and may be used to contact you if necessary. - Your username is
        used to register your account and log in to your account. When you use
        the functionalities of our app, such as adding places, adding
        commentaries, adding photos, your username is used to show what you
        post. We do not disclose any of your personal data, except username,
        which is published in the app together with commentaries, places or
        photos which you post. In the result thereof, your username, as well as
        places, commentaries and photos which you add are disclosed within the
        app for the users of our app (registered and non-registered), as well as
        on our website.
      </p>
      <h5>DO WE DISCLOSE ANY INFORMATION TO OUTSIDE PARTIES?</h5>
      <p>No</p>

      <h5>IS YOUR PERSONAL DATA SECURED?</h5>
      <p>
        We handle the data of our users in a secured infrastructure. We have
        implemented encryption of transmission when you provide your data over
        the Internet.
      </p>

      <h3>Zasady Użytkowania aplikacji SpotFinder (PL)</h3>

      <h5>UGC (User Generated Content)</h5>
      <p>
        SpotFinder to aplikacja społecznościowa, która umożliwia użytkownikom
        udostępnianie miejsc które ich zdaniem posiadają wyjątkowe walory.
        Korzystanie z tej usługi ma charakter wyłącznie osobisty, non-profit.
        Dodanie miejsc należących do prywatnych osób nie powinno być dodawane
        bez ich uprzedniej zgody. Nasz zespół nie może zagwarantować dokładności
        ani legalności wymienianych informacji, opisów, adresów, danych
        kontaktowych, cen, komentarzy i zdjęc. SpotFinder zastrzega sobie prawo
        do moderowania, usuwania i modyfikowania informacji dodanych przez
        użytkowników: wszelkiego rodzaju opisów, adresów, danych kontaktowych,
        cen oraz zdjęc,które nasz zespół uzna za niezgodne z regulaminem.
        Spotfinder nie ponosi odpowiedzialności za działania i konsekwencje,
        które mogą wyniknąć z używania informacji uzyskanych za pośrednictwem
        naszej aplikacji. Użytkownicy powinni dodawać miejsca zgodnie z główną
        ideą aplikacji, ale nie możemy tego zagwarantować. Istnieje
        niebezpieczeństwo, że natkniesz się na treści promujące alkohol, hazard
        lub pornografie (wszystkie tego rodzaju treści są usuwane z aplikacji
        tak szybko, jak to możliwe). Kiedy napotkasz nieodpowiednie tresci
        wewnątrz aplikacji do ich zgłoszenia można wykorzystać wbudowany system
        reportowania. Przyjrzymy się tej zawartości i kiedy zdecydujemy, że
        zawiera niedozwolone treści usuniemy je. Jako użytkownik w aplikacji
        społeczności użytkowników jesteś odpowiedzialny za treści, które
        dostarczasz i postaraj się zrobic wszystko, co w Twojej mocy, aby ta
        aplikacja była świetnym, miejscem.
      </p>

      <h5>JAKIE DANE OSOBOWE PRZETRZYMUJEMY?</h5>
      <p>
        Aby korzystać z naszej aplikacji nie musisz rejestrować konta. Jednakże,
        jeśli chcesz w pełni cieszyć się funkcjonalnością spotfindera, musisz
        Utworzyć konto; W takim przypadku niezbędne minimum danych osobowych
        jakie musi być podane to: adres e-mail, nazwa użytkownika (utworzona
        przez Ciebie, nie musi być twoje prawdziwe imię). Możemy zbierać inne
        informacje, ale nie traktowane jako dane osobowe, takie jak: typ
        telefonu, preferowane język, opisy zaczerpnięte z komentarzy i zdjęć.
        Nasza aplikacja wykorzystuje Google Analytics, która jest usługą
        oferowaną przez Google, która generuje szczegółowe statystyki dotyczące
        ruchu w naszej aplikacji. Ta usługa jest świadczona przez Google zgodnie
        z polityką prywatności Google.
      </p>
      <h5>DO CZEGO WYKORZYSTUJEMY TE INFORMACJE?</h5>
      <p>
        Twój adres e-mail jest używany w celu: rejestracji konta, logowania do
        Twojego konta i może być wykorzystany do skontaktowania się z Tobą,
        jeśli to konieczne. - Twoja nazwa użytkownika to używane do rejestracji
        konta i logowania do konta. Kiedy używasz funkcjonalności naszej
        aplikacji, takie jak dodawanie miejsc, dodawanie komentarze, dodawanie
        zdjęć, Twoja nazwa użytkownika służy do pokazania tego, co Ty post. Nie
        ujawniamy żadnych Twoich danych osobowych, z wyjątkiem nazwy
        użytkownika, który jest publikowany w aplikacji wraz z komentarzami,
        miejscami lub zdjęcia, które publikujesz. W wyniku tego Twoja nazwa
        użytkownika, jak również miejsca, komentarze i zdjęcia, które dodajesz,
        są ujawniane w ramach tzw app dla użytkowników naszej aplikacji
        (zarejestrowanych i niezarejestrowanych), jak również na naszej stronie
        internetowej.
      </p>
      <h5>CZY PRZEKAZUJEMY JAKIES DANE DO PARTNERÓW ZEWNETRZNYCH?</h5>
      <p>Nie</p>

      <h5>CZY TWOJE DANE SA BEZPIECZNE?</h5>
      <p>
        Przetwarzamy dane naszych użytkowników w zabezpieczonej infrastrukturze.
        Mamy wdrożone szyfrowanie transmisji, gdy przekazujesz swoje dane przez
        Internet.
      </p>

      <h3>Spotfinder website privacy rules</h3>
      <p>
        Our website uses cookies to provide you with the best user experience
        possible. If you do not want cookies to be stored on your computer,
        please change your browser settings. Cookies are not used to obtain any
        information about the users of the website or their navigation
        behaviour. Cookies used by spotfinder do not contain any personal data
        or any other information collected from users. We use two types of
        cookies - session cookies and persistent cookies. A session cookie is a
        temporary file which expires automatically when you end your session or
        close your browser. A persistent cookie will remain on the user device
        for the time defined within the cookie file or until it is deleted.
      </p>
      <p>
        The logs that we gather are stored indefinitely as supplementary
        material used to administer the website. The information contained in
        these logs is not disclosed to any other persons than those authorised
        to administer our servers. Statistics may be derived from the log files
        in order to help administering our servers. Summary statistics like
        those do not contain any date identifying users browsing the website.
      </p>
      <p>
        Spotfinder website stores HTTP queries directed to our servers. This
        means that we know the public IP addresses of the users who browse our
        website. The resources being browsed are identified by URL adresses. We
        also know: the time a query was posted, the time the query was answered,
        the client station's name - identified by the HTTP protocol, information
        on any errors thet occured during a HTTP transaction, the URL address of
        the page previously visited by the user (refer link) in situations where
        entering the website occored via a link, information on the users
        browser type
      </p>
    </div>
  );
};

export default PolicyRules;
