import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { setLanguage } from '../../store/actions/language/language';
import UserSessionDataHandler from '../../auth/UserSessionDataHandler';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const DefaultTheme = (props) => {
  const { saveUserConfiguration } = props;
  const dispatch = useDispatch();
  const [lang, setLang] = React.useState(
    UserSessionDataHandler.getSettings()?.language || 'pl'
  );
  const strings = useSelector((state) => state.language.user.settings);
  const classes = useStyles();

  const handleChange = (event) => {
    const oldConfiguration = UserSessionDataHandler.getSettings();
    const newConfiguration = {
      ...oldConfiguration,
      language: event.target.value,
    };
    saveUserConfiguration(JSON.stringify(newConfiguration));
    UserSessionDataHandler.saveSettings(newConfiguration);
    dispatch(setLanguage(event.target.value));
    setLang(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label1">
        {strings.language.header_}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label1"
        id="demo-simple-select1"
        value={lang}
        onChange={handleChange}
      >
        <MenuItem value="pl">{strings.language.pl_}</MenuItem>
        <MenuItem value="en">{strings.language.en_}</MenuItem>
      </Select>
    </FormControl>
  );
};

DefaultTheme.propTypes = {
  saveUserConfiguration: PropTypes.func.isRequired,
};

export default DefaultTheme;
