export default {
  login: 'user/login',
  register: 'user/register',
  confirmUser: 'user/confirm',
  resetPassword: 'user/resetPassword',
  setNewPassword: 'user/setNewPassword',
  setUserConfiguration: 'user/setUserConfiguration',
  localizations: 'localization',
  extentLocalizations: 'localization/extent',
  postsFromLocalizations: 'post/localizations',
  user: 'user',
  postLike: 'like/post',
  comments: 'comment',
  commentLike: 'like/comment',
  post: 'post',
  postToLocalization: 'post/localization',
  categories: 'category',
  notifications: 'notification',
  groupedLocalizations: 'localization/groupedByPlace/categories',
  getPostByComment: 'comment/withPost/byCommentUid',
  setNotificationAsSeen: 'notification/seen',
};
