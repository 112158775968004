import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as proj from 'ol/proj';
import * as extent from 'ol/extent';
import {
  addLayerToMap,
  createLayer,
  getAllClickedFeatures,
  addCurrentLocalizations,
  getFeatureFromLayerById,
} from '../map/utils/main';
import { authGetRequestWithParams } from '../../helpers/apiRequests';
import { setSelectedLocalization } from '../../store/actions/localization/selectedLocalization';
import {
  setBasicLocalizationStyle,
  setSelectedLocalizationStyle,
} from './utils/map';
import { setPosts } from '../../store/actions/post/post';

const Localizations = (props) => {
  const { map, localizationFilters } = props;
  const localizationsLayer = React.useRef(null);
  const groupedLocalizationsLayer = React.useRef(null);
  const [click, setClick] = React.useState(null);
  const groupedLocalizationsRef = React.useRef([]);
  const localizationFiltersRef = React.useRef(localizationFilters);
  const selectedLocalizationRef = React.useRef(null);
  const selectedLocalization = useSelector(
    (state) => state.selectedLocalization
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!Object.keys(map).length) return;
    map.on('click', setClick);
    map.on('moveend', handleMapChange);
    localizationsLayer.current = createLayer('dashboardLocalizations');
    groupedLocalizationsLayer.current = createLayer('groupedLocalizations');
    addLayerToMap(map, localizationsLayer.current);
    addLayerToMap(map, groupedLocalizationsLayer.current);
    // handleDisplaySelectedLocalization();

    // return () => removeComponentLayers(map);
  }, [map]);

  // const handleDisplaySelectedLocalization = () => {
  //   if (!selectedLocalization) return;
  //   setSelectedLocalizationStyle(selectedLocalization);
  //   addFeatureToLayer(localizationsLayer.current, selectedLocalization);
  //   centerMapToCordinates(
  //     map,
  //     selectedLocalization.getGeometry().getCoordinates()
  //   );
  // };

  React.useEffect(() => {
    if (!click) return;
    const clickedFeatures = getAllClickedFeatures(map, click).filter(
      (feature) => !!feature.get('clickable')
    );

    if (!clickedFeatures.length) unselectLocalization();
    else handleLocalizationSelect(clickedFeatures);
  }, [click]);

  const handleLocalizationSelect = (clickedFeatures) => {
    if (!selectedLocalization) {
      setSelectedLocalizationStyle(clickedFeatures[0]);
      dispatch(setSelectedLocalization(clickedFeatures[0]));
    } else if (clickedFeatures[0].getId() !== selectedLocalization.getId()) {
      setBasicLocalizationStyle(selectedLocalization);
      setSelectedLocalizationStyle(clickedFeatures[0]);
      dispatch(setSelectedLocalization(clickedFeatures[0]));
    }
    [selectedLocalizationRef.current] = clickedFeatures;
  };

  const unselectLocalization = () => {
    const feature = getFeatureFromLayerById(
      localizationsLayer.current,
      selectedLocalizationRef.current?.getId()
    );
    setBasicLocalizationStyle(feature);
    dispatch(setPosts([]));
    dispatch(setSelectedLocalization(null));
    selectedLocalizationRef.current = null;
  };

  React.useEffect(() => {
    localizationFiltersRef.current = localizationFilters;
    handleMapChange();
  }, [localizationFilters]);

  const handleMapChange = async () => {
    if (!localizationFiltersRef.current.length) return;
    groupedLocalizationsRef.current = [];
    const mapExtent = map.getView().calculateExtent();
    if (mapExtent) {
      const result = await authGetRequestWithParams(
        'extentLocalizations',
        {
          minX: proj.toLonLat(extent.getTopLeft(mapExtent))[0],
          maxX: proj.toLonLat(extent.getTopRight(mapExtent))[0],
          minY: proj.toLonLat(extent.getBottomRight(mapExtent))[1],
          maxY: proj.toLonLat(extent.getTopRight(mapExtent))[1],
        },
        {
          categories: localizationFiltersRef.current || localizationFilters,
        }
      );

      if (result.status === 200) handleLocalizationsChange(result.data);
    }
  };

  const handleLocalizationsChange = (currentLocalizations) => {
    const currentlySelectedLocalization = selectedLocalizationRef.current
      ? selectedLocalizationRef.current.getId()
      : null;

    addCurrentLocalizations(
      localizationsLayer.current,
      currentLocalizations,
      currentlySelectedLocalization
    );
  };

  return null;
};

const mapStateToProps = (state) => ({
  map: state.map,
  localizationFilters: state.localizationFilters,
});

export default connect(mapStateToProps)(Localizations);
