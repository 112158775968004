/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HideIcon from '@material-ui/icons/ArrowDownward';
import ShowIcon from '@material-ui/icons/ArrowUpward';
import RedoIcon from '@material-ui/icons/Redo';
import { useDispatch, useSelector } from 'react-redux';
import Map from '../map/Main';
import Localizations from '../localizations/Main';
import { authGetRequest } from '../../helpers/apiRequests';
import Posts from '../posts/Main';
import CustomButton from '../common/AddButton';
import AddPost from '../posts/Add';
import { setCategories } from '../../store/actions/category/category';
import LocalizationFilters from './Filters';

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    overflowX: 'hidden',
  },
  container: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 'auto',
    paddingTop: 40,
    borderRadius: 10,
    [theme.breakpoints.up('md')]: {
      overflowX: 'hidden',
      overflowY: 'auto',
      height: 'calc(100vh - 130px)',
      paddingTop: theme.spacing(2),
      position: 'absolute',
      right: 60,
      top: 80,
      width: 500,
    },
  },
  mapPaper: {
    width: '100%',
    height: 'calc(100vh - 220px)',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 64px)',
    },
  },
  hideButton: {
    position: 'fixed',
    bottom: 50,
    right: 17,
    minWidth: 20,
    paddingLeft: 8,
    paddingRight: 8,
    display: 'none',
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  displayBlock: {
    display: 'block',
  },
  displayNone: {
    display: 'none',
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const localizations = useSelector((state) => state.localizations);
  const selectedLocalization = useSelector(
    (state) => state.selectedLocalization
  );
  const [addPost, setAddPost] = React.useState(false);
  const [showDetails, setShowDetails] = React.useState(true);

  React.useEffect(() => {
    authGetRequest('categories').then((result) => {
      if (result.status === 200) dispatch(setCategories(result.data));
    });
  }, []);

  const addPostToggle = () => {
    setAddPost(!addPost);
  };

  React.useEffect(() => {
    if (window.screen.width < 1000) return;

    setShowDetails(!!selectedLocalization);
  }, [selectedLocalization]);

  const hideButton = () => (
    <Button
      variant="contained"
      color="primary"
      className={classes.hideButton}
      onClick={() => setShowDetails(!showDetails)}
    >
      {showDetails ? <HideIcon /> : <ShowIcon />}
    </Button>
  );

  return (
    <>
      <Grid item xs={12}>
        <Paper className={classes.mapPaper}>
          <Map />
          <Localizations localizations={localizations} />
        </Paper>
      </Grid>
      {hideButton()}

      <Grid
        item
        xs={12}
        md={12}
        lg={5}
        className={
          showDetails ? `${classes.displayBlock}` : `${classes.displayNone}`
        }
      >
        <Paper className={classes.paper}>
          {selectedLocalization ? (
            addPost ? (
              <>
                <AddPost
                  uid={selectedLocalization.getId()}
                  addPostToggle={addPostToggle}
                />
                <CustomButton
                  onClickHandler={addPostToggle}
                  top={80}
                  right={30}
                  content={<RedoIcon />}
                />
              </>
            ) : (
              <>
                <Posts />
                <CustomButton
                  top={80}
                  right={30}
                  onClickHandler={addPostToggle}
                />
              </>
            )
          ) : (
            <Grid item xs={12}>
              <LocalizationFilters />
            </Grid>
          )}
        </Paper>
      </Grid>
    </>
  );
};

export default Dashboard;
