import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { authGetRequestWithParams } from '../../helpers/apiRequests';
import { addAlert } from '../../store/actions/alert/alert';
import CommentWrapper from './Wrapper';
import AddComment from './Add';
import { editPost } from '../../store/actions/post/post';
import LoadMoreButton from '../common/LoadMoreButton';
import { NUMBER_OF_POSTS_PER_REQUEST } from '../../consts/appConfig';

const MainComments = (props) => {
  const { post } = props;
  const [comments, setComments] = React.useState([]);
  const [showButton, setShowButton] = React.useState(
    post.comments?.length % NUMBER_OF_POSTS_PER_REQUEST === 0
  );
  const dispatch = useDispatch();
  const strings = useSelector((state) => state.language);

  React.useEffect(() => {
    getComments();
  }, []);

  const getComments = (addComments = false) => {
    authGetRequestWithParams('comments', {
      uid: post.uid,
      offset: addComments ? post.comments.length : 0,
    }).then((result) => {
      if (result.status !== 200)
        dispatch(
          addAlert({
            title: strings.alerts.dataProccessError.title_,
            desc: result.message,
            type: 'error',
          })
        );
      else {
        if (!result.data.length) {
          setShowButton(false);
          return;
        }
        if (result.data.length % NUMBER_OF_POSTS_PER_REQUEST === 0)
          setShowButton(true);
        else setShowButton(false);
        if (addComments)
          dispatch(
            editPost({ ...post, comments: [...post.comments, ...result.data] })
          );
        else dispatch(editPost({ ...post, comments: result.data }));
      }
    });
  };

  React.useEffect(() => {
    if (post.comments && post.comments.length && post.comments[0].likes) {
      setComments(post.comments);
    }
  }, [post.comments]);

  return (
    <>
      {comments.length
        ? post.comments.map((comment) => (
            <CommentWrapper comment={comment} post={post} key={comment.uid} />
          ))
        : null}
      {showButton && (
        <LoadMoreButton
          onClickHandler={() => getComments(true)}
          content="More..."
        />
      )}
      <AddComment post={post} />
    </>
  );
};

MainComments.propTypes = {
  post: PropTypes.object.isRequired,
};

export default MainComments;
