import { makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  instruction: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      color: '#BDD7E4',
      position: 'absolute',
      bottom: 80,
      left: 80,
      minWidth: 200,
      maxWidth: '20vw',
    },
  },
  text: {
    fontSize: '1.2rem',
  },
}));

const AppDescription = (props) => {
  const { paragraphs } = props;
  const classes = useStyles();

  return (
    <header className={classes.instruction}>
      <h1 className={classes.text}>{paragraphs.first_}</h1>
      <h1 className={classes.text}>{paragraphs.second_}</h1>
      <h1 className={classes.text}>{paragraphs.third_}</h1>
    </header>
  );
};

AppDescription.propTypes = {
  paragraphs: PropTypes.object.isRequired,
};

export default AppDescription;
