import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { authPostRequest } from '../../helpers/apiRequests';
import { addAlert } from '../../store/actions/alert/alert';
import { checkAndCompressFile } from './utils/main';

const useStyles = makeStyles({
  errorAlert: {
    marginBottom: 40,
  },
});

const AddPost = (props) => {
  const { uid, addPostToggle } = props;
  const classes = useStyles();
  const strings = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const [file, setFile] = React.useState(null);
  const [values, setValues] = React.useState({
    title: '',
    description: '',
  });
  const [errors, setErrors] = React.useState({
    title: false,
    description: false,
  });
  const [tooBigFileError, setTooBigFileError] = React.useState(false);

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleFile = (event) => {
    setFile(event.target.files?.[0]);
  };

  const onSaveClick = async () => {
    if (!isDataValid()) return;
    const formData = await prepareData();
    if (!formData) {
      setTooBigFileError(true);
      return;
    }
    authPostRequest('postToLocalization', formData).then((result) => {
      if (result.status !== 201)
        dispatch(
          addAlert({
            title: strings.alerts.dataProccessError.title_,
            desc: result.message,
            type: 'error',
          })
        );
      else {
        addPostToggle();
        dispatch(
          addAlert({
            title: strings.alerts.addDataSuccess.title_,
            desc: strings.alerts.addDataSuccess.desc_,
            type: 'success',
          })
        );
      }
    });
  };

  const prepareData = async () => {
    const formData = new FormData();
    for (const key of Object.keys(values)) {
      formData.append(key, values[key]);
    }
    if (file) {
      const image = await checkAndCompressFile(file);
      if (!image) return null;
      formData.append(file.type.split('/')[0], image);
    }

    formData.append('localizationUid', uid);

    return formData;
  };

  const isDataValid = () => {
    for (const key of Object.keys(values)) {
      if (values[key] === '') {
        setErrors({ ...errors, [key]: true });
        return false;
      }
      setErrors({ ...errors, [key]: false });
    }

    return true;
  };

  const rederErrorAlert = () => (
    <Alert severity="error" variant="outlined" className={classes.errorAlert}>
      <h5>{strings.posts.add.tooBigFile_}</h5>
    </Alert>
  );

  const showError = (fieldName) =>
    values[fieldName] === '' && errors[fieldName];

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid item xs={12}>
        <Grid item xs={12}>
          <h1>{strings.posts.add.header_}</h1>
        </Grid>
        {tooBigFileError && rederErrorAlert()}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              defaultValue={values.title}
              label={strings.posts.add.title_}
              variant="outlined"
              error={showError('title')}
              fullWidth
              onChange={handleChange}
              name="title"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values.description}
              label={strings.posts.add.text_}
              variant="outlined"
              fullWidth
              multiline
              error={showError('description')}
              rows={10}
              rowsMax={30}
              onChange={handleChange}
              name="description"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" component="label" onChange={handleFile}>
              {strings.posts.add.file_}
              <input
                type="file"
                accept=".png, .jpg, .jpeg, .webm, .mp4"
                hidden
                name="file"
              />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <span>{file ? `Wybrano: ${file.name}` : ''} </span>
          </Grid>
          <Grid item xs={12} lg={4} />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.marginTop}
            onClick={() => onSaveClick()}
          >
            {strings.posts.add.button_}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

AddPost.propTypes = {
  uid: PropTypes.string.isRequired,
  addPostToggle: PropTypes.func.isRequired,
};

export default AddPost;
